import CircularContainer from "../../../modules/CircularContainer/CircularContainer";
import Image, { ImageProps } from "../Image";

type CircularImageProps = {
  image: ImageProps["image"];
  imageAlt: ImageProps["alt"];
};

const CircularImage = ({ image, imageAlt }: CircularImageProps) => {
  return (
    <CircularContainer
      sx={{
        "& .gatsby-image-wrapper": {
          height: "100%",
        },
      }}
    >
      <Image alt={imageAlt} image={image} style={{ height: "100%" }} />
    </CircularContainer>
  );
};

export default CircularImage;
