import { isNil } from "lodash";
import React, { useMemo } from "react";
import { buildImageData } from "../../../utils/image/buildImageData";
import Carousel from "../../views/Carousel/Carousel";

type CMSCarouselProps = {
  data: GatsbyTypes.WeArePageQuery["prismicWeArePage"]["data"]["carousel"];
};

const CMSCarousel = ({ data }: CMSCarouselProps) => {
  const imagesData = useMemo(
    () => data?.map(({ image }) => buildImageData(image)),
    [data]
  );

  if (isNil(imagesData)) {
    return null;
  }

  return <Carousel imagesData={imagesData} />;
};

export default CMSCarousel;
