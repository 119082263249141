import { Grid } from "@mui/material";
import { isNil } from "lodash";
import { ReactNode } from "react";
import RichText from "../../../elements/RichText/RichText";

export enum ContentCTextAlignment {
  LEFT = "left",
  RIGHT = "right",
}

export type ContentCProps = {
  title?: string;
  body: string;
  children: ReactNode;
  textAlignment?: ContentCTextAlignment;
};

const ContentC = ({
  title,
  body,
  children,
  textAlignment = ContentCTextAlignment.LEFT,
}: ContentCProps) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      direction={
        textAlignment === ContentCTextAlignment.LEFT ? "row" : "row-reverse"
      }
      sx={{
        paddingTop: {
          md: 2,
        },
        paddingBottom: {
          md: 2,
        },
      }}
    >
      <Grid
        container
        item
        rowSpacing={2}
        columnSpacing={2}
        xs={12}
        md={6.5}
        direction={
          textAlignment === ContentCTextAlignment.LEFT ? "row-reverse" : "row"
        }
      >
        <Grid item container xs={12} md={6} lg={5} justifyContent="center">
          <Grid item xs={8} md={12} sx={{ position: "relative" }}>
            {children}
          </Grid>
        </Grid>
        <Grid container item xs={12} md={6} lg={7} alignContent="center">
          {!isNil(title) && (
            <Grid item xs={12}>
              <RichText text={title} textProps={{ textAlign: "center" }} />
            </Grid>
          )}
          {!isNil(body) && (
            <Grid item xs={12}>
              <RichText text={body} textProps={{ textAlign: "center" }} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContentC;
