import { Box } from "@mui/system";
import { graphql, PageProps } from "gatsby";
import { isNil } from "lodash";
import InViewAnimationGrid from "../components/behaviors/InViewAnimation/InViewAnimationGrid";
import PageTransitionWrapper from "../components/behaviors/PageTransitionWrapper/PageTransitionWrapper";
import CMSCarousel from "../components/cms/Carousel/CMSCarousel";
import CMSContentCGroup from "../components/cms/ContentCGroup/CMSContentCGroup";
import CMSHeroA from "../components/cms/HeroA/CMSHeroA";
import CMSIntroText from "../components/cms/IntroText/CMSIntroText";
import PageSpacerWrapper from "../components/elements/PageSpacerWrapper/PageSpacerWrapper";
import RichText from "../components/elements/RichText/RichText";
import PageWrapBreakout from "../components/modules/PageWrapBreakout/PageWrapBreakout";
import Seo from "../components/seo";

const WeAre = ({ data }: PageProps<GatsbyTypes.WeArePageQuery>) => {
  const pageData = data?.prismicWeArePage?.data;

  return (
    <PageTransitionWrapper pageId="we-are-page">
      <Seo
        title={pageData?.seo_title}
        description={pageData?.seo_description}
      />
      <PageSpacerWrapper>
        {!isNil(pageData?.hero) && (
          <PageWrapBreakout>
            <CMSHeroA data={pageData.hero} />
          </PageWrapBreakout>
        )}
        {!isNil(pageData?.intro_text) && (
          <CMSIntroText data={pageData.intro_text} />
        )}
        {!isNil(pageData?.carousel) && <CMSCarousel data={pageData.carousel} />}
        {!isNil(pageData?.description?.html) && (
          <InViewAnimationGrid>
            <RichText text={pageData.description.html} />
          </InViewAnimationGrid>
        )}
        {!isNil(pageData?.content_c_group) && (
          <PageWrapBreakout>
            <Box
              sx={{
                paddingLeft: {
                  xs: 2,
                  sm: 7,
                },
                paddingRight: {
                  xs: 2,
                  sm: 7,
                },
              }}
            >
              <CMSContentCGroup data={pageData.content_c_group} />
            </Box>
          </PageWrapBreakout>
        )}
      </PageSpacerWrapper>
    </PageTransitionWrapper>
  );
};

export default WeAre;

export const query = graphql`
  query WeArePage {
    prismicWeArePage {
      data {
        hero {
          button_label
          button_link {
            document {
              ... on PrismicLandingPage {
                url
              }
              ... on PrismicCompanyPage {
                url
              }
              ... on PrismicProjectsPage {
                url
              }
              ... on PrismicServicesPage {
                url
              }
              ... on PrismicWeArePage {
                url
              }
              ... on PrismicContactPage {
                url
              }
              ... on PrismicPropertyFormPage {
                url
              }
            }
            url
            localFile {
              publicURL
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
          mobile_image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        intro_text {
          html
        }
        carousel {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        description {
          html
        }
        content_c_group {
          title {
            html
          }
          body {
            html
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        seo_description
        seo_title
      }
    }
  }
`;
