import { Grid } from "@mui/material";
import { isNil } from "lodash";
import { useMemo } from "react";
import { buildImageData } from "../../../utils/image/buildImageData";
import InViewAnimationGrid from "../../behaviors/InViewAnimation/InViewAnimationGrid";
import CircularImage from "../../elements/Image/CircularImage/CircularImage";
import ContentC, {
  ContentCTextAlignment,
} from "../../views/Content/ContentC/ContentC";

type ContentCGroupProps = {
  data: GatsbyTypes.WeArePageQuery["prismicWeArePage"]["data"]["content_c_group"];
};

const CMSContentCGroup = ({ data }: ContentCGroupProps) => {
  const builtData = useMemo(
    () =>
      data?.map(({ title, body, image }) => ({
        title: title.html,
        body: body.html,
        image: buildImageData(image),
      })),
    [data]
  );

  if (isNil(builtData)) {
    return null;
  }

  return (
    <Grid container rowSpacing={{ xs: 6, sm: 8 }}>
      {builtData.map(({ title, body, image }, index) => (
        <InViewAnimationGrid key={body} item xs={12}>
          <ContentC
            title={title}
            body={body}
            textAlignment={
              index % 2 === 0
                ? ContentCTextAlignment.LEFT
                : ContentCTextAlignment.RIGHT
            }
          >
            <CircularImage image={image.image} imageAlt={image.alt} />
          </ContentC>
        </InViewAnimationGrid>
      ))}
    </Grid>
  );
};

export default CMSContentCGroup;
