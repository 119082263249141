import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Swiper as SwiperType } from "swiper";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import { ArrowBackRounded, ArrowForwardRounded } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { ImageData } from "../../../utils/image/buildImageData";
import Image from "../../elements/Image/Image";
import { useCallback, useState } from "react";
import InViewAnimationGrid from "../../behaviors/InViewAnimation/InViewAnimationGrid";

type CarouselProps = {
  imagesData: ImageData[];
};

const Carousel = ({ imagesData }: CarouselProps) => {
  const [swiper, setSwiper] = useState<SwiperType>();

  const handlePrev = useCallback(() => {
    swiper?.slidePrev();
  }, [swiper]);

  const handleNext = useCallback(() => {
    swiper?.slideNext();
  }, [swiper]);

  return (
    <InViewAnimationGrid
      container
      sx={{ position: "relative", overflow: "hidden" }}
    >
      <Grid
        container
        justifyContent="space-between"
        sx={{
          position: "absolute",
          top: "50%",
          width: "100%",
          transform: "translate(0, -50%)",
          zIndex: 2,
          paddingLeft: {
            xs: 2,
            md: 5,
            lg: 9,
          },
          paddingRight: {
            xs: 2,
            md: 5,
            lg: 9,
          },
        }}
      >
        <Grid item container xs="auto" alignItems="center">
          <ArrowBackRounded
            sx={{
              fontSize: {
                xs: 40,
                sm: 80,
              },
              cursor: "pointer",
              "&:focus, &:hover": { transform: "scale(1.1)" },
              "&:active": { transform: "scale(1)" },
              outline: "none",
            }}
            onClick={handlePrev}
          />
        </Grid>
        <Grid item container xs="auto" alignItems="center">
          <ArrowForwardRounded
            sx={{
              fontSize: {
                xs: 40,
                sm: 80,
              },
              cursor: "pointer",
              "&:focus, &:hover": { transform: "scale(1.1)" },
              "&:active": { transform: "scale(1)" },
              outline: "none",
            }}
            onClick={handleNext}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          margin: "auto",
          width: {
            xs: "100%",
            md: "75%",
          },
        }}
      >
        <Swiper
          modules={[EffectFade, Autoplay]}
          effect="fade"
          onSwiper={(swiper) => setSwiper(swiper)}
          spaceBetween={0}
          slidesPerView={1}
          speed={1000}
          autoplay={{
            delay: 5000,
          }}
          loop
          style={{ height: "100%" }}
        >
          {imagesData.map(({ image, alt }, index) => {
            return (
              <SwiperSlide key={index}>
                <Image alt={alt} image={image} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </InViewAnimationGrid>
  );
};

export default Carousel;
