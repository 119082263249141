import React, { ReactNode } from "react";
import { Box, BoxProps } from "@mui/material";

type CircularContainerProps = {
  children: ReactNode;
  sx?: BoxProps["sx"];
};

const CircularContainer = ({ children, sx = {} }: CircularContainerProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "0",
        position: "relative",
        paddingTop: "100%",
        border: (theme) => `5px solid ${theme.palette.secondary.main}`,
        borderRadius: "50%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          overflow: "hidden",
          zIndex: 0,
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default CircularContainer;
